html,
body {
  height: 100%;
  font-family: sans-serif;
}

body {
  margin: 0;
  padding: 0;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.content {
  flex: 1 0 auto;
  padding: 0px;
}

.footer {
  flex-shrink: 0;
  text-align: center;
  background-color: white;
  color: white;
}
